import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import H1 from "../components/H1"
import H2 from "../components/H2"
import Container from "../components/Container"
import Hero from "../components/Hero"
import PricingTable from "../components/PricingTable"
import HeroImage from "../components/Hero/HeroImage"
import HeroButton from "../components/Hero/HeroButton"
import theme from "../themes"
import panda_and_clock from "../images/panda_and_clock.svg"
import panda_umbrella from "../images/panda_umbrella.svg"
import panda_sleep from "../images/panda_sleep.svg"
import pet_trace_black from "../images/pet_trace_black.svg"
import { graphql } from "gatsby"
import Table from "../components/PricingTable/Table"
import TableRow from "../components/TableRow"
import ImageBlock from "../components/ImageBlock"
import TableData from "../components/TableData"
import TableBody from "../components/TableBody"
import P from "../components/P"
import AdditionalCosts from "../components/AdditionalCosts"
import { Grid } from "@material-ui/core"

const FlexiPage = ({ data }) => {
  console.log(data)

  return (
    <Layout>
      <SEO title="Flexi" />
      <Hero bgColor={theme.main.colors.pinkLight}>
        <Container height={"600px"} heightMd={"600px"}>
          <H1>Flexi</H1>
          <p>Für Eltern mit flexiblen Arbeitstagen.</p>
        </Container>
        <ImageBlock
          src={panda_and_clock}
          width={"55%"}
          widthSm={"55%"}
          widthMd={"45%"}
          widthLg={"20%"}
          position={{
            position: "absolute",
            bottom: "30%",
            right: "0",
          }}
          positionSm={{
            bottom: "-7%",
          }}
          positionMd={{
            bottom: "-0%",
          }}
          margin={"0 0 0 120px"}
          marginSm={"0 0 40px 30%"}
        />
        <HeroButton bottom={"20%"} to="/flexi#orientation" stripHash>
          Mehr erfahren
        </HeroButton>
      </Hero>
      <Section id="orientation">
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <ImageBlock
                src={panda_umbrella}
                width={"100%"}
                widthSm={"60%"}
                widthMd={"100%"}
                margin={"0 0 0 -30%"}
                marginSm={"0 0 0 -10%"}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <H2>Wir sind flexibel. Bei uns stehen Eltern nicht im Regen.</H2>
              <p>
                Bei Teilzeitbeschäftigungen sind die Arbeitstage nicht immer
                unbedingt dieselben. Zudem variieren die Arbeitszeiten, häufig
                sogar wöchentlich. Daher bieten wir den Erziehungsberechtigten
                eine flexible Möglichkeit, ihre Betreuungstage zu wählen.
              </p>
              <p>
                Bei Buchung des Flexi-Programms, besteht die Möglichkeit, uns
                die Betreuungstage einen Monat im Voraus mitzuteilen, damit wir
                diese planen können.
              </p>
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section id="pricing">
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={5}>
              <H2>
                Unsere Tarife sind transparent. Für einen ruhigen Schlaf ohne
                Überraschungen.
              </H2>
            </Grid>

            <Grid item xs={12} md={7} style={{ overflow: "hidden" }}>
              <ImageBlock
                src={panda_sleep}
                width={"120%"}
                widthSm={"100%"}
                widthMd={"100%"}
                margin={"0 0 40px 20%"}
                marginSm={"0 0 40px 30%"}
              />
            </Grid>
          </Grid>
        </Container>

        <Container>
          {console.log()}

          <Table>
            <TableBody>
              <TableRow>
                <TableData>
                  <b>{data.strapiPricingFlexi.title}</b>
                </TableData>
                <TableData>
                  <b>{data.strapiPricingFlexi.value}</b>
                </TableData>
              </TableRow>

              {console.log(data.strapiPricingFlexi)}

              {data.strapiPricingFlexi.data.map((item, index) => (
                <TableRow key={index}>
                  <TableData>{item.title}</TableData>
                  <TableData>{item.value}</TableData>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Table>
            <TableBody>
              <TableRow>
                <TableData>
                  <b>{data.strapiPricingFlexi.title2}</b>
                </TableData>
                <TableData>
                  <b>{data.strapiPricingFlexi.value2}</b>
                </TableData>
              </TableRow>

              {console.log(data.strapiPricingFlexi)}

              {data.strapiPricingFlexi.data2.map((item, index) => (
                <TableRow key={index}>
                  <TableData>{item.title}</TableData>
                  <TableData>{item.value}</TableData>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <AdditionalCosts />
        </Container>
      </Section>
    </Layout>
  )
}

export default FlexiPage

export const query = graphql`
  query {
    strapiPricingFlexi {
      title
      value
      data {
        title
        value
      }
      title2
      value2
      data2 {
        title
        value
      }
    }

    strapiPricingStandard {
      title
      value
      data {
        title
        value
      }
    }
  }
`
